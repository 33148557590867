import { PublicKey } from "@solana/web3.js";


// export const STAKE_PROGRAM_PUBKEY = new PublicKey("8CXcuasx4tVjFeSs1D7P39TnEhq8GKSJYYiRtAnG4kPo");

// export const MINT_ADDRESS = new PublicKey("AxaTJdRuuc3626FtPWdQCMcWPH6yzgxXKWbFCZN3TMgy");


// devnet

export const STAKE_PROGRAM_PUBKEY = new PublicKey("8Pux41xQojMqKF4p1dZCYoELxiNuoEuK5yECikqkB9r6");

export const MINT_ADDRESS = new PublicKey("HTPHknF5rBNCuZ1qK3muJA6Yo1HEhZj722oSESJnPBiw");

