import { useMemo } from 'react'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react'
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui'
import { GlowWalletAdapter, PhantomWalletAdapter, SlopeWalletAdapter, SolflareWalletAdapter, TorusWalletAdapter } from '@solana/wallet-adapter-wallets'
import { clusterApiUrl } from '@solana/web3.js'

export const WalletConnectProvider = ({ children }) => {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    // const network = WalletAdapterNetwork['mainnet-beta'] // mainnet
    const network = WalletAdapterNetwork.Mainnet // devnet

    // You can also provide a custom RPC endpoint.
    const endpoint = useMemo(() => {
        // if (network === WalletAdapterNetwork['mainnet-beta']) {
        //     return 'https://solana-mainnet.g.alchemy.com/v2/c-xvJn9qahtHsEnnAH73xpqSaf9xEKOt'
        // }

        if (network === WalletAdapterNetwork.Mainnet) {
            return 'https://solana-mainnet.api.syndica.io/api-token/2F3r9bWjivvhh7d5hRXYwEoLAhJmZ7vTQGvuPuWoNpHTe3TG64x6Yr5JJJtXJAhLaG48c3cx7vPSGyaHVQhym4T3n9Yj7xtsdRMEfCbfZ8GjG2GtcfzthW1JxdYoFPBTwVY8RsMUK88jSPNjJZMEGkg822hAYCcypvbyvJHmQG2oBKmGt2HHaQsQY3UoMdN7ovPiKLmubm6kFoNcXPD6zXkaCUqHTKHf85DU17q3RQNxGexs7WP8jBTA3HfWzH2pdFLjw51MPx5VEwfwKNs7eVZpSqfUhaGp9bKAzS7XvEL1spWWA1XWKcc6j6EDxd1KcgivpWq2cn7SP7LRp4mgwypHVS1vWHs1J3ZSD2qhi2ZDTJfCc2WpFJvNhQ7cJogYLaVLaZxRjuqhCRjf4mfxPTaW6zQwFLzX2Yi56P8y7dkMWM8Jg2thgEycLc6fWFdvkwQQv2ptgJ1YyFpS8SKtfTr1xoUATKfYt2obHqg9hHu286jsC8ZJHrg8RXMQo'
        }

        return clusterApiUrl(network)
    }, [network])

    // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
    // Only the wallets you configure here will be compiled into your application, and only the dependencies
    // of wallets that your users connect to will be loaded.
    const wallets = useMemo(() => [new PhantomWalletAdapter(), new SolflareWalletAdapter({ network }), new TorusWalletAdapter()], [network])

    return (
        <ConnectionProvider endpoint={endpoint} config={{commitment: 'confirmed', confirmTransactionInitialTimeout: 60000}}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    )
}